import React from 'react';
import styled from 'styled-components';

import mdToHtml from '../../util/mdToHtml';

import whiteHex from '../../../static/img/white-hex.svg';

const StyledDoubleCopy = styled.section`
	background-image: url(${(p) => p.img});
	background-position: center bottom;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: ${(p) => p.theme.color.snowflake};

	.columns {
		background-image: url(${whiteHex});
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: -3rem 0;
		padding: 6.25rem 0;

		@media (max-width: 768px) {
			margin: 0;
			padding: 1rem 0;
			background-position: center bottom;
		}

		@media (max-width: 600px) {
			background-size: 100% 50%;
			background-position: center 80%;
		}

		> .column > .image-container {
			max-width: 500px;
			margin: 0 auto 1rem;
		}

		&:nth-child(odd) {
			@media (min-width: 769px) {
				background-position: right center;
			}
		}

		&:nth-child(even) {
			@media (min-width: 769px) {
				background-position: left center;
			}

			> .column:nth-child(2) > .image-container {
				@media (min-width: 769px) {
					margin-left: auto;
				}
			}
		}
	}
`;

const DoubleCopy = ({ row1, row2, row3, bgImg }) => {
	return (
		<StyledDoubleCopy className="section" img={bgImg}>
			<div className="container">
				<div className="double-container">
					<div className="columns">
						<div className="column">
							<div className="image-container">
								<img src={row1.img} alt="ek construction hard hat" />
							</div>
						</div>
						<div className="column text-column">
							<div className="text-container">
								<h2>{row1.title}</h2>
								<div
									dangerouslySetInnerHTML={{ __html: mdToHtml(row1.text) }}
								/>
							</div>
						</div>
					</div>
					<div className="columns column-reverse">
						<div className="column text-column push">
							<div className="text-container">
								<h2>{row2.title}</h2>
								<div
									dangerouslySetInnerHTML={{ __html: mdToHtml(row2.text) }}
								/>
							</div>
						</div>
						<div className="column pull">
							<div className="image-container">
								<img src={row2.img} alt="ek construction harmer" />
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="image-container">
								<img src={row3.img} alt="ek construction harmer" />
							</div>
						</div>
						<div className="column text-column">
							<div className="text-container">
								<h2>{row3.title}</h2>
								<div
									dangerouslySetInnerHTML={{ __html: mdToHtml(row3.text) }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledDoubleCopy>
	);
};

export default DoubleCopy;
