import React from 'react';
import styled from 'styled-components';

const StyledMasthead = styled.div`
	padding: ${(p) => p.top} ${(p) => p.theme.gutter} ${(p) => p.bottom};
	background-image: url(${(p) => p.img});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	.uber-heading,
	.brief {
		font-family: ${(p) => p.theme.fonts.body};
		text-transform: uppercase;
	}

	.uber-heading {
		font-weight: 800;
		font-size: 1.125rem;
		color: ${(p) => p.theme.color.lightBlue};
		margin-bottom: 1em;
	}

	.brief {
		font-weight: 600;
		color: ${(p) => p.theme.color.moreThanAWeek};
		margin-top: 1em;
		line-height: 1.3;
	}

	@media all and (min-width: 1200px) {
		padding: ${(p) => p.top} ${(p) => p.theme.gutterWide} ${(p) => p.bottom};
	}
	@media all and (min-width: 1600px) {
		padding: ${(p) => p.wide} ${(p) => p.theme.gutterXWide} ${(p) => p.bottom};
		h1 {
			width: ${(p) => p.width.wide};
		}
		.brief {
			font-size: 1.3rem;
			line-height: 2.7rem;
		}
	}
	@media all and (max-width: 500px) {
		padding: ${(p) => p.mobileTop} ${(p) => p.theme.gutterMobile}
			${(p) => p.mobileBottom};
		h1 {
			width: 85%;
			font-size: 2rem;
			line-height: 2.5rem;
		}
		.brief {
			display: block;
		}
	}
	@media all and (max-width: 400px) {
		padding: ${(p) => p.mobileTop} ${(p) => p.theme.gutterMobile}
			${(p) => p.mobileBottom};

		h1 {
			width: 100%;
		}
	}
`;

const Masthead = ({ title, img, page, brief }) => {
	let top, bottom, wide, mobileTop, mobileBottom;
	const width =
		page === 'project'
			? { desktop: '100%', wide: '100%' }
			: { desktop: '280px', wide: '370px' };

	switch (page) {
		case 'home':
			top = '15rem';
			bottom = '8rem';
			wide = '16rem';
			mobileTop = '10rem';
			mobileBottom = '4rem';
			break;
		case 'project':
			top = '10rem';
			bottom = '5rem';
			wide = '10rem';
			mobileTop = '10rem';
			mobileBottom = '5rem';
			break;
		default:
			// all other pages
			top = '12rem';
			bottom = '8rem';
			wide = '13rem';
			mobileTop = '10rem';
			mobileBottom = '4rem';
	}

	return (
		<StyledMasthead
			img={img}
			top={top}
			bottom={bottom}
			wide={wide}
			width={width}
			mobileBottom={mobileBottom}
			mobileTop={mobileTop}
		>
			{page === 'project' && <p className="uber-heading">Projects</p>}
			<h1 dangerouslySetInnerHTML={{ __html: title }} />
			{page === 'project' && <p className="brief">{brief}</p>}
		</StyledMasthead>
	);
};

export default Masthead;
