import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledGallery = styled.div`
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	display: flex;
	position: relative;

	.button-container {
		display: inline-block;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.left {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 25rem;
		background-image: url(${(p) => p.commercial});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-right: 2px solid #fff;
	}
	.right {
		width: 50%;
		.half-row {
			height: 50%;
			display: flex;
			&:last-child {
				background-image: url(${(p) => p.residential});
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 2px solid #fff;
			}
			.half {
				width: 50%;
				&:first-child {
					background-image: url(${(p) => p.left});
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					border-right: 2px solid #fff;
				}
				&:last-child {
					background-image: url(${(p) => p.right});
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
				}
			}
		}
	}
	@media all and (min-width: 1600px) {
		.left {
			height: 35rem;
		}
	}
	@media all and (max-width: 500px) {
		flex-direction: column;
		.left {
			width: 100%;
			height: 15rem;
			border-right: none;
			border-bottom: 2px solid #fff;
		}
		.right {
			width: 100%;
			.half-row {
				flex-direction: column;
				&:last-child {
					height: 15rem;
				}
				.half {
					width: 100%;
					height: 15rem;
					&:first-child {
						border-right: none;
						border-bottom: 2px solid #fff;
					}
				}
			}
		}
	}
`;

const Gallery = ({ commercial, left, right, residential }) => {
	return (
		<StyledGallery
			commercial={commercial}
			left={left}
			right={right}
			residential={residential}
		>
			<div className="left"></div>
			<div className="right">
				<div className="half-row">
					<div className="half"></div>
					<div className="half"></div>
				</div>
				<div className="half-row"></div>
			</div>
			<div className="button-container">
				<Link to="/projects/" className="blueButton">
					VIEW OUR PROJECTS
				</Link>
			</div>
		</StyledGallery>
	);
};

export default Gallery;
