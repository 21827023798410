import React from 'react';

import Layout from '../components/layout';
import HomeTemplate from '../templates/index-template';
import SEO from '../components/seo';

const copy = {
	description:
		'EK Construction Co. offers turn-key solutions and full service commercial and residential contracting in Simcoe, Muskoka, York and the GTA Regions.',
	masthead: {
		title: 'Build Well. <br />Live Well.',
		img: '/img/EK_Masthead_v4.jpg',
	},
	intro: {
		bgImg: '/img/ConcreteBG_light_top.jpg',
		title: 'The EK Construction Co. <br />Building Simcoe County Since 2013',
		text:
			'The EK Construction Company has been building in the Simcoe County area since 2013, and we are proud of the strong ties to our local communities that we have formed. Our relationships with the people we work with — home owners, business owners, materials and service suppliers, and our own employees — define the culture of who we are, and the quality of what we have accomplished. Build well, and live well with the EK Construction Company.',
		img: {
			asset: '/img/EK_Hex_Logo.svg',
			alt: 'EK Construction',
		},
	},
	double: {
		bgImg: '/img/ConcreteBG_light_bottom.jpg',
		row1: {
			title: 'Project Delivery',
			text:
				'The project delivery method defines the positions of the three primary roles – Designer, Constructor, Owner – within the scope of a construction project. The method of project delivery will determine who is responsible for what, and the role of each under the contract. While there are many project delivery methods available, The EK Construction Company typically works with the following methods: \n\n* **Traditional** (lump-sum general contracting) \n* **Design-Build**',
			img: '/img/project-delivery.jpg',
		},
		row2: {
			title: 'Professional Services',
			text:
				'Professional services can include such duties as general contracting, construction management, consulting, design, architecture, and feasibility. The EK Construction Company is happy to offer the following professional services: \n\n* **General Contracting** \n* **Construction Management** \n* **Consulting**',
			img: '/img/pro-construction.jpg',
		},
		row3: {
			title: 'Trade Services',
			text:
				'Trade services include any and all preparatory and installation services. This includes all aspects of actual construction, from the first shovel in the ground, to the final touch of paint. Our in-house trades have extensive experience in concrete, metals, wood, all forms of thermal and moisture protection, openings, and all kinds of finish installations. We are also so proud of the strong relationships we have fostered with our preferred trade contractors. Our network of experienced trade contractors, coupled with our in-house field operatives, gives our company significant flexibility which allows us to adapt to the ever-changing project-to-project scope.',
			img: '/img/trade-services.jpg',
		},
	},
	gallery: {
		commercial: '/img/EK_Home_Commercial_1.jpg',
		residential: '/img/EK_Home_Residential_1.jpg',
		left: '/img/EK_Home_Commercial_3.jpg',
		right: '/img/EK_Home_Commercial.jpg',
	},

	reviews: [
		{
			title: 'Raj, Brampton',
			text:
				'We’d rate EK as a top choice for contractors. Their professionalism should be highly regarded from the moment you get a quote with',
			source: {
				text: 'Houzz.com',
				link:
					'https://www.houzz.com/pro/ekconstructioncompany/ek-construction-company',
			},
		},
		{
			title: 'Mandeep, Toronto',
			text:
				'Ean and his team have been working on my project since the start of the year and it’s been nothing short of fantastic',
			source: {
				text: 'Houzz.com',
				link:
					'https://www.houzz.com/pro/ekconstructioncompany/ek-construction-company',
			},
		},
		{
			title: 'Albert, Barrie',
			text:
				'Ean was most professional when he was requested to repair wall damage in the store. He took the time to listen and to understand',
			source: {
				text: 'Houzz.com',
				link:
					'https://www.houzz.com/pro/ekconstructioncompany/ek-construction-company',
			},
		},
	],

	footer: {
		text: 'Let’s discuss your next commercial or residential project.',
		cta: {
			text: 'CONTACT US',
			link: '/contact/',
		},
		img: '/img/EK_Home_Bottom.jpg',
	},
};

const IndexPage = () => {
	return (
		<Layout>
			<SEO title="Home" description={copy.description} />
			<HomeTemplate copy={copy} />
		</Layout>
	);
};

export default IndexPage;
