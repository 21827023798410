export default {
	color: {
		lightBlue: '#2CA2DC',
		waterBlue: '#0D88CA',
		blue: '#1A67CF',
		yellow: '#FDC132',
		grey: '#707070',
		lightGrey: '#878787',
		darkGrey: '#353535',
		carbon: '#313534',
		moreThanAWeek: '#8D8D8D',
		cerebralGrey: '#cccccc',
		light: '#ECECEC',
		snowflake: '#F0F0F0',
		lightest: '#ffffff',
	},
	fonts: {
		nav: 'Anton, sans-serif;',
		body: 'Merriweather Sans, sans-serif',
	},
	gutterMobile: '2rem',
	gutter: '4rem',
	gutterWide: '8rem',
	gutterXWide: '12rem',
};
