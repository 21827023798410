import React from 'react';
import styled from 'styled-components';

const StyledIntro = styled.section`
	background-image: url(${(p) => p.img});
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: ${(p) => p.theme.color.snowflake};

	.intro-columns-container {
		padding: 3.25rem 0 6.25rem;
		border-bottom: 2px solid ${(p) => p.theme.color.moreThanAWeek};

		@media (max-width: 770px) {
			padding-bottom: 2rem;
		}

		.text-container {
			padding-bottom: 3rem;

			* {
				color: inherit;
			}

			@media (min-width: 771px) {
				padding-right: 5rem;
			}

			@media (max-width: 770px) {
				padding-bottom: 0;
			}
		}

		.img-container {
			max-width: 560px;
			margin: 0 auto;

			@media (min-width: 1000px) {
				margin-top: -180px;
			}

			@media (max-width: 770px) {
				display: none;
			}
		}
	}
`;

const Intro = ({ bgImg, title, text, img }) => {
	return (
		<StyledIntro className="section" img={bgImg}>
			<div className="container">
				<div className="intro-columns-container">
					<div className="columns">
						<div className="column">
							<div className="text-container">
								<h2 dangerouslySetInnerHTML={{ __html: title }} />
								<p>{text}</p>
							</div>
						</div>
						<div className="column">
							<div className="img-container">
								<img src={img.asset} alt={img.alt} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledIntro>
	);
};

export default Intro;
