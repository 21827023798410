import { createGlobalStyle } from 'styled-components';

import bulma from './bulmaSetup.scss';
import reset from './reset.css';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Anton|Merriweather+Sans:300,300i,400,800&display=swap');

	${reset}
	${bulma}

	* {
		box-sizing: border-box;
	}

	html {
		font-size: 16px;
	}

	body {
		font-size: 16px;
		color: ${(p) => p.theme.color.carbon} !important;
		background-color: #fff;
		font-family: ${(props) => props.theme.fonts.body} !important;
		overflow: hidden;
		h1 {
      font-family: ${(p) => p.theme.fonts.nav};
			font-size: 4.375rem;
			line-height: 1.14;
      text-transform: uppercase;
      letter-spacing: 0.02em;
			color: #fff;
		}
		h2 {
			font-size: 2.0625rem;
			line-height: 1.36;
			font-weight: 700;
			color: inherit;
      margin-bottom: 1em;
		}
		p {
			line-height: 1.5rem;
    }
    
    i {
      font-style: italic;
    }

		button {
			cursor: pointer;
			appearance: none;
			padding: 0;
			margin: 0;
			font-family: inherit;
			font-size: 100%;
    		text-decoration: none;
			border: none;
			color: #fff;
			background-color: transparent;
		}
		a, button {
			color: ${(p) => p.theme.color.grey};
			transition: .5s;

			&.blueButton {
				color: #fff;
				background-color: ${(p) => p.theme.color.waterBlue};
				padding: 0.7rem 1.25rem;
				font-family: ${(props) => props.theme.fonts.nav};
				transition: 0.5s;
				&:hover {
					background-color: ${(p) => p.theme.color.lightBlue};
				}
			}
			&.lightButton {
				color: ${(p) => p.theme.color.blue};
				background-color: #fff;
				padding: 0.7rem 1.25rem;
				font-family: ${(props) => props.theme.fonts.nav};
				transition: 0.5s;
				&:hover {
					color: ${(p) => p.theme.color.lightBlue};
				}
			}
		}
		input, textarea {
			border: 3px solid ${(p) => p.theme.color.cerebralGrey};
			border-radius: 0;
			font-size: 1rem;
			font-family: ${(props) => props.theme.fonts.body};
      margin-bottom: 0.5rem;
		}
		span.bold{
			font-weight: 700;
		}
    .text-container {
      margin-bottom: 1.5rem;

      * {
        color: inherit;
      }

      p {
        margin-bottom: 1.875em;
      }

      ul {
        list-style: disc;
        padding-left: 1em;
        margin-bottom: 1em;

        li {
          margin-bottom: 0.5em;
          line-height: 1.3;

          p {
            margin-bottom: 0;
          }
        }
      }

      strong {
        font-weight: 600;
      }
    }
		@media all and (min-width: 1600px) {
			h1 {
				font-size: 4rem;
				line-height: 4.5rem;
			}
			h2 {
				font-size: 2rem;
				line-height: 2.5rem;
			}
			p {
				font-size: 1.25rem;
				line-height: 1.5;
			}
			a, a.blueButton, button.blueButton {
				font-size: 1.5rem
				line-height: 2rem;
			 }
		}

    @media (max-width: 770px) {
      .column-reverse {
        display: flex;
        flex-direction: column-reverse;
      }
    }

`;

export default GlobalStyle;
