import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledFooterCTA = styled.div`
	padding: 4rem ${(p) => p.theme.gutterMobile};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-image: url(${(p) => p.img});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	h2 {
		max-width: ${(p) => p.textWidth};
		margin-bottom: 2rem;
		font-weight: 400;
		color: ${(p) => p.theme.color.lightest};
	}
	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
		h2 {
			width: ${(p) => p.textWidthWide};
		}
	}
	@media all and (max-width: 500px) {
		h2 {
			width: 100%;
		}
	}
`;

const FooterCTA = ({ text, cta, img, page }) => {
	return (
		<StyledFooterCTA
			img={img}
			textWidth={page === 'contact' ? '11em' : '17em'}
			textWidthWide={page === 'contact' ? '11em' : '17em'}
		>
			<h2>{text}</h2>
			<Link className="blueButton" to={cta.link}>
				{cta.text}
			</Link>
		</StyledFooterCTA>
	);
};

export default FooterCTA;
