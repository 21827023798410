import styled from 'styled-components';

export default styled.div`
	.navbar {
		position: absolute;
		padding: 1rem ${(p) => p.theme.gutter};
		background-color: ${(p) => p.theme.color.lightest};
		width: 100%;

		@media all and (max-width: 768px) {
			padding: 0.5rem;
		}
		.mobile-overlay {
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			opacity: 0;
		}
		&.mobileOpen .mobile-overlay {
			width: 100%;
		}
		&.is-fixed-top {
			opacity: 0;
			transition: 0.3s;
			display: flex;
			position: fixed;
			z-index: 29;
			background-image: url(/img/sticky-nav.jpg);
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 3.5rem;
			min-height: 3.5rem;
			padding: 0 4rem;
			@media all and (min-width: 1600px) {
				padding: 0 12rem;
			}
			@media all and (max-width: 768px) {
				display: none;
			}
			&.scrolled {
				opacity: 1;
				transition: 0.5s;
			}
			.navbar-brand {
				min-height: auto;
			}
		}
		.navbar-brand a {
			padding: 0;
			position: relative;
			width: 250px;
			img {
				max-height: none;
			}
		}
		.white-logo,
		.mobile-content,
		.phone {
			display: none;
		}
		a {
			color: ${(p) => p.theme.color.waterBlue};
			font-family: Anton, sans-serif;
			text-transform: uppercase;
			padding: 0 1rem;
			font-size: 1.1rem;
			background: none !important;
			transition: 0.5s;
			&:hover {
				color: ${(p) => p.theme.color.lightBlue} !important;
				transition: 0.5s;
			}
		}
		@media all and (min-width: 1200px) {
			padding: 1rem ${(p) => p.theme.gutterWide};
		}
		@media all and (min-width: 1600px) {
			padding: 1rem ${(p) => p.theme.gutterXWide};
			.logo {
				width: 200px;
			}
			a {
				font-size: 1.3rem;
				line-height: 1.5rem;
				padding: 0 2rem;
			}
		}
		@media all and (max-width: 820px) {
			padding: 2rem 2.5rem;
		}
		@media all and (max-width: 770px) {
			.navbar-burger {
				display: block;
				color: ${(p) => p.theme.color.blue};
				position: absolute;
				right: 30px;
				z-index: 999;
				transition: 0.4s;
				background: none;
				height: 4rem;
				width: 4rem;
				top: 25px;
				.hex {
					position: absolute;
					left: 2px;
					height: 4rem;
					width: 4rem;
					transform: rotate(30deg);
				}
				.x {
					position: absolute;
					left: 25px;
					top: 23px;
					height: 1.1rem;
					width: 1.1rem;
					transform: rotate(45deg);
					transition: 0.4s;
				}

				&.is-active {
					transform: rotate(120deg);
					.x {
						transform: rotate(150deg);
					}
				}
			}

			.navbar-menu {
				display: block;
				padding: 2rem 0 0;
				position: fixed;
				left: 0;
				right: 0;
				top: -495px;
				opacity: 0;
				background-color: rgba(26, 103, 207, 0.95);
				transition: top 0.4s cubic-bezier(0.08, 1, 0.36, 1),
					opacity 0s ease-in 0.4s;
				&.is-active {
					transition: top 0.4s cubic-bezier(0.08, 1, 0.36, 1), opacity 0s;
					opacity: 1;
					top: 0px;
				}
				.navbar-item {
					font-size: 2rem;
					padding-left: 2.5rem;
					color: ${(p) => p.theme.color.lightest};

					&.white-logo {
						display: flex;
						margin-bottom: 2rem;
						left: 2px;
						top: 4px;
						.logo-container {
							width: 247px;
							height: 50px;
							img {
								max-height: none;
							}
						}
					}
					img {
						backface-visibility: hidden;
					}
				}
				.mobile-content {
					padding-left: 2.5rem;
					display: block;
					color: #fff;
					font-weight: 200;
					margin: 2rem 0 2.5rem;
					width: 470px;
				}
				.phone {
					display: flex;
					align-items: center;
					background-color: #1d72d5;
					padding: 1rem 2.5rem;
					width: 100%;
					a {
						padding: 0;
						font-size: 1.5rem;
						margin-left: 1rem;
						color: ${(p) => p.theme.color.lightest};
					}
				}
			}
		}
		@media all and (max-width: 500px) {
			padding: 1.5rem 2rem;
			.navbar-brand a {
				width: 200px;
			}
			.navbar-burger {
				top: 18px;
			}
			.navbar-menu {
				padding: 1.7rem 0 0;
				top: -475px;
				.navbar-item {
					padding-left: 2rem;
					&.white-logo {
						margin-bottom: 1rem;
						left: 0px;
						top: 5px;
						.logo-container {
							width: 200px;
						}
					}
				}
				.mobile-content {
					padding: 0 2rem;
					width: 100%;
				}
			}
		}
		@media all and (max-width: 450px) {
			.navbar-menu {
				top: -500px;
			}
		}
		@media all and (max-width: 340px) {
			.navbar-burger {
				right: 22px;
			}
			.navbar-menu {
				top: -520px;
			}
		}
	}
`;
