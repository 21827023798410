import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	background-image: url(/img/EK_Home_Bottom_Concrete.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	nav {
		font-family: ${(p) => p.theme.fonts.nav};
		font-size: 1.125rem;
		font-weight: 500;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 ${(p) => p.theme.gutter};
		height: 10rem;
	}
	.logo {
		width: 250px;
	}
	.right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		a {
			padding: 0 1rem;
			color: #fff;
			font-weight: 700;
			&:hover {
				color: ${(p) => p.theme.color.lightBlue};
			}
		}
	}
	.footer-bottom {
		text-align: center;
		position: relative;
		top: -1rem;
		height: 2rem;
		color: #8a8a8a;
		font-size: 0.8rem;
	}
	@media all and (min-width: 1200px) {
		padding: 0rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 0rem ${(p) => p.theme.gutterXWide};
		a {
			font-size: 1.3rem;
		}
		p {
			font-size: 1rem;
		}
	}
	@media all and (max-width: 820px) {
		nav {
			padding: 0 2.5rem;
		}
	}
	@media all and (max-width: 770px) {
		nav {
			justify-content: center;
			flex-direction: column;
			height: 18rem;

			.right {
				justify-content: center;
				flex-direction: column;
				align-items: center;
				margin: 2rem 0 0;
				a {
					padding: 0.8rem 0;
				}
			}
		}
	}
`;

const Footer = () => {
	return (
		<StyledFooter>
			<nav>
				<div className="logo">
					<Link to="/">
						<img src="/img/EK_Logo.svg" alt="EK Construction Logo" />
					</Link>
				</div>
				<div className="right">
					<Link to="/our-company/">Our Company</Link>
					<Link to="/building-solutions/">Building Solutions</Link>
					<Link to="/projects/">Projects</Link>
					<Link to="/contact/">Contact Us</Link>
				</div>
			</nav>
			<div className="footer-bottom">
				<p>&copy; {new Date().getFullYear()} EK CONSTRUCTION CO.</p>
			</div>
		</StyledFooter>
	);
};

export default Footer;
