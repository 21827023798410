import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';

import NavStyle from '../styles/navStyle.js';

const NavMenu = () => {
	const [mobileMenu, setMobileMenu] = useState(false);
	const [scroll, setScroll] = useState(0);
	const [headerHeight, setHeaderHeight] = useState(100);

	useEffect(() => {
		const handleResize = () => {
			window.innerWidth < 1600 ? setHeaderHeight(85) : setHeaderHeight(99);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	// Event handler utilizing useCallback ...
	// ... so that reference never changes.
	const handler = useCallback(() => {
		setScroll(window.scrollY);
	}, [setScroll]);

	scrollListener(handler);

	function scrollListener(handler) {
		// Create a ref that stores handler
		const savedHandler = useRef();

		// Update ref.current value if handler changes.
		// This allows our effect below to always get latest handler ...
		// ... without us needing to pass it in effect deps array ...
		// ... and potentially cause effect to re-run every render.
		useEffect(() => {
			savedHandler.current = handler;
		}, [handler]);

		useEffect(
			() => {
				// Create event listener that calls handler function stored in ref
				const eventListener = (event) => savedHandler.current(event);

				// Add event listener
				global.addEventListener('scroll', eventListener);

				// Remove event listener on cleanup
				return () => {
					global.removeEventListener('scroll', eventListener);
				};
			},
			['scroll', global] // Re-run if eventName or element changes
		);
	}

	const navClass = mobileMenu ? 'navbar mobileOpen' : 'navbar';

	return (
		<NavStyle>
			<nav
				className={scroll > headerHeight ? navClass + ' scrolled' : navClass}
			>
				<div
					className="mobile-overlay"
					onClick={() => {
						if (!mobileMenu) setMobileMenu(true);
						else setMobileMenu(false);
					}}
				/>
				<div className="navbar-brand" onClick={(e) => e.stopPropagation()}>
					<Link className="navbar-item" to="/">
						<img src="/img/EK_Logo.svg" alt="EK Construction Logo" />
					</Link>
					<div
						role="button"
						className={mobileMenu ? 'navbar-burger is-active' : 'navbar-burger'}
						onClick={() => {
							if (!mobileMenu) setMobileMenu(true);
							else setMobileMenu(false);
						}}
					>
						<img className="hex" src="/img/hexagon.svg" alt="menu button" />
						<img className="x" src="/img/x.svg" alt="menu button" />
					</div>
				</div>
				<div className={mobileMenu ? 'navbar-menu is-active' : 'navbar-menu'}>
					<div className="navbar-end">
						<Link className="navbar-item white-logo" to="/">
							<div className="logo-container">
								<img src="/img/EK_Logo_white.svg" alt="EK Construction Logo" />
							</div>
						</Link>
						<Link className="navbar-item" to="/our-company/">
							Our Company
						</Link>
						<Link className="navbar-item" to="/building-solutions/">
							Building Solutions
						</Link>
						<Link className="navbar-item" to="/projects/">
							Projects
						</Link>
						<Link className="navbar-item" to="/contact/">
							Contact Us
						</Link>
						<div className="mobile-content">
							<p>
								<span className="bold">EK Construction Co.</span> is a turn-key
								solutions, full service contracting firm, located in Barrie, ON.
							</p>
						</div>
						<div className="phone">
							<img src="/img/phone.svg" alt="phone icon" />
							<a href="tel:+17059100449">(705) 910-0449</a>
						</div>
					</div>
				</div>
			</nav>
			<nav
				className={
					scroll > headerHeight
						? 'navbar is-fixed-top scrolled'
						: 'navbar is-fixed-top'
				}
			>
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						<img src="/img/EK_Logo.svg" alt="EK Construction Logo" />
					</Link>
				</div>
				<div className="navbar-menu">
					<div className="navbar-end">
						<Link className="navbar-item" to="/our-company/">
							Our Company
						</Link>
						<Link className="navbar-item" to="/building-solutions/">
							Building Solutions
						</Link>
						<Link className="navbar-item" to="/projects/">
							Projects
						</Link>
						<Link className="navbar-item" to="/contact/">
							Contact Us
						</Link>
					</div>
				</div>
			</nav>
		</NavStyle>
	);
};

export default NavMenu;
