import React from 'react';

import Masthead from '../components/masthead';
import Intro from '../components/home/intro';
import Double from '../components/home/double';
import Gallery from '../components/home/gallery';
import Reviews from '../components/home/reviews';
import Footer from '../components/footer-cta';

const HomeTemplate = ({ copy }) => {
	const { masthead, intro, footer, double, gallery, reviews } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} page="home" />
			<Intro
				bgImg={intro.bgImg}
				title={intro.title}
				text={intro.text}
				img={intro.img}
			/>
			<Double
				row1={double.row1}
				row2={double.row2}
				row3={double.row3}
				bgImg={double.bgImg}
			/>
			<Gallery
				commercial={gallery.commercial}
				left={gallery.left}
				right={gallery.right}
				residential={gallery.residential}
			/>
			<Reviews reviews={reviews} />
			<Footer text={footer.text} cta={footer.cta} img={footer.img} />
		</>
	);
};

export default HomeTemplate;
