import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

const StyledReviews = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	background-color: ${(p) => p.theme.color.light};
	display: flex;

	.slider-list {
		margin-bottom: 50px !important;
	}
	.bubble {
		background-color: ${(p) => p.theme.color.grey};
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		border-bottom-left-radius: 30px;
		padding: 1rem 1.5rem;
		margin: 0 1rem;
		color: #fff;
		width: calc(33.3% - 1rem);
		margin-right: 1.5rem;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			display: block;
			/*   left width */
			border-left: 25px solid transparent;
			/*   right width */
			border-right: 25px solid transparent;
			/*   height */
			border-bottom: 25px solid ${(p) => p.theme.color.grey};
			transform: rotate(45deg);
			right: -16px;
			bottom: -20px;
		}
		p {
			margin: 1rem 0 0.5rem;
			font-weight: 200;
		}
		.stars {
			margin-bottom: 0.5rem;
		}
		a {
			color: #fff;
			font-size: 0.7rem;
			&:hover {
				color: ${(p) => p.theme.color.lightBlue};
			}
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide} 5rem;
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide} 7rem;
		.bubble {
			padding: 2rem 2rem;
			a {
				font-size: 1rem;
			}
		}
	}
	@media all and (max-width: 960px) {
		.slider-slide {
			padding: 0 1rem 2rem;
		}
		.bubble {
			width: auto;
		}
		.dots {
			display: flex;
			position: relative;
			top: 30px;
			span {
				width: 15px;
				height: 15px;
				margin: 0 5px;
				border-radius: 10px;
				transition: 0.5s;
				background-color: #fff;
				&.active {
					background-color: ${(p) => p.theme.color.grey};
				}
			}
		}
	}
	@media all and (max-width: 700px) {
		.dots span:last-child {
			display: block;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile} 4rem;
	}
`;

const Reviews = ({ reviews }) => {
	const [slidesToShow, setSlides] = useState(3);
	const [showSlider, setShowSlider] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 960) {
				setTimeout(() => {
					let height1 = document.querySelector(
						'.slider-slide:nth-child(1) .bubble'
					).offsetHeight;
					let height2 = document.querySelector(
						'.slider-slide:nth-child(2) .bubble'
					).offsetHeight;
					let height3 = document.querySelector(
						'.slider-slide:nth-child(3) .bubble'
					).offsetHeight;

					let maxHeight = Math.max(height1, height2, height3);

					document.querySelector(
						'.slider-slide:nth-child(1) .bubble'
					).style.height = `${maxHeight}px`;
					document.querySelector(
						'.slider-slide:nth-child(2) .bubble'
					).style.height = `${maxHeight}px`;
					document.querySelector(
						'.slider-slide:nth-child(3) .bubble'
					).style.height = `${maxHeight}px`;
				}, 500);
				setSlides(2);
				setShowSlider(true);
				if (window.innerWidth <= 700) {
					setSlides(1);
					setShowSlider(true);
				}
			} else {
				setSlides(3);
				setShowSlider(false);
			}
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledReviews>
			<>
				{showSlider && (
					<Carousel
						autoplay={true}
						wrapAround={true}
						slidesToShow={slidesToShow}
						renderCenterRightControls={null}
						renderCenterLeftControls={null}
						renderBottomCenterControls={({ goToSlide, currentSlide }) => (
							<div className="dots">
								<span
									className={currentSlide === 0 ? 'active' : ''}
									onClick={() => {
										goToSlide(0);
									}}
								/>
								<span
									className={currentSlide === 1 ? 'active' : ''}
									onClick={() => {
										goToSlide(1);
									}}
								/>
								<span
									className={currentSlide === 2 ? 'active' : ''}
									onClick={() => {
										goToSlide(2);
									}}
								/>
							</div>
						)}
					>
						{reviews.map((item, index) => (
							<div className="bubble" key={index}>
								<h2>{item.title}</h2>
								<p>{item.text}...</p>
								<div className="stars">
									<img src="/img/stars.svg" alt="5 star rating" />
								</div>
								<a
									href={item.source.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.source.text}
								</a>
							</div>
						))}
					</Carousel>
				)}
				{!showSlider && (
					<>
						{reviews.map((item, index) => (
							<div className="bubble" key={index}>
								<h2>{item.title}</h2>
								<p>{item.text}...</p>
								<div className="stars">
									<img src="/img/stars.svg" alt="5 star rating" />
								</div>
								<a
									href={item.source.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.source.text}
								</a>
							</div>
						))}
					</>
				)}
			</>
		</StyledReviews>
	);
};

export default Reviews;
